import React from 'react';
import './styles.scss';

const PrimaryButton = (props) => {
  const { text, onClick, tabIndex, size = 'sm', submit } = props;

  return (
    <button
      type={submit ? 'submit' : 'button'}
      tabIndex={tabIndex}
      className={`primary-button ${size} button-animation`}
      onClick={onClick}
      onKeyDown={onClick}
    >
      <span>{text}</span>
    </button>
  );
};

export default PrimaryButton;
