import React from 'react';
import './styles.scss';

const Section = ({ id, style, children }) => (
  <div className="section-wrapper" id={id} style={style}>
    {children}
  </div>
);

export default Section;
