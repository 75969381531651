import React from 'react';
import Helmet from 'react-helmet';
import Twitter from './Twitter';
import Facebook from './Facebook';

const SEO = ({ seo, url }) => (
  <>
    <Helmet title={seo.title} titleTemplate={seo.title}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="keywords" content={seo.keywords} data-react-helmet="true" />
    </Helmet>
    <Facebook
      pageUrl={url}
      title={seo.title}
      description={seo.description}
      image={seo.image}
      appID={seo.facebookAppID}
    />
    <Twitter
      username={seo.twitterUsername}
      title={seo.title}
      description={seo.description}
      image={seo.image}
    />
  </>
);

export default SEO;
